import axios from '@/libs/axios'

class TalkCenter {
  static list(params) {
    return axios.post('admin/talk-centers', params)
  }

  static store(params) {
    return axios.post('admin/talk-centers/store', params)
  }

  static view(id) {
    return axios.post(`admin/talk-centers/view/${id}`)
  }

  static update(params) {
    return axios.post('admin/talk-centers/update', params)
  }

  static destroy(id) {
    return axios.post('admin/talk-centers/delete', { id })
  }

  static updateTakeOperationTimes(params) {
    return axios.post('admin/talk-centers/talk-operation-times', params)
  }
}

export default TalkCenter
